/**
 * Note: This Slice is used only within the project and is not made available
 * to editors in Prismic.
 */

import * as React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import clsx from 'clsx'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { Text } from '../components/Text'
import { ButtonLink } from '../components/ButtonLink'
import { DEFAULT_ONLINE_ORDERING_URL } from '../constants'
import { hash } from '../lib/hash'
import { ButtonProps } from '../components/Button'

type Color = {
	image: React.ReactElement
	bg: string
	heading: string
	details: string
	orderButtonVariant: ButtonProps['variant']
	menuButtonVariant: ButtonProps['variant']
}
type Scheme = 'red' | 'beige' | 'blue'

const colorSchemes: Record<Scheme, Color> = {
	red: {
		image: (
			<StaticImage
				src="../../static/images/location-illustration-red.png"
				alt=""
				loading="eager"
				placeholder="none"
				formats={['webp']}
				sizes="(min-width: 48rem) 52rem, 90vw"
				quality={90}
				breakpoints={[420, 768, 832, 1280, 1664]}
			/>
		),
		bg: 'bg-[#D03146]',
		heading: 'text-beige-80',
		details: 'text-beige-80',
		orderButtonVariant: 'tealSmall',
		menuButtonVariant: 'beigeSmall',
	},
	beige: {
		image: (
			<StaticImage
				src="../../static/images/location-illustration-beige.png"
				alt=""
				loading="eager"
				placeholder="none"
				formats={['webp']}
				sizes="(min-width: 48rem) 52rem, 90vw"
				quality={90}
				breakpoints={[420, 768, 832, 1280, 1664]}
			/>
		),
		bg: 'bg-beige-80',
		heading: 'text-red-50',
		details: 'text-red-50',
		orderButtonVariant: 'redSmall',
		menuButtonVariant: 'tealSmall',
	},
	blue: {
		image: (
			<StaticImage
				src="../../static/images/location-illustration-blue.png"
				alt=""
				loading="eager"
				placeholder="none"
				formats={['webp']}
				sizes="(min-width: 48rem) 52rem, 90vw"
				quality={90}
				breakpoints={[420, 768, 832, 1280, 1664]}
			/>
		),
		bg: 'bg-[#52A5A2]',
		heading: 'text-beige-80',
		details: 'text-beige-80',
		orderButtonVariant: 'redSmall',
		menuButtonVariant: 'beigeSmall',
	},
}
const schemes = Object.keys(colorSchemes) as Scheme[]

export type PageBodyLocationIntroProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyLocationIntro = ({
	title,
	type,
	id,
	locationDetails,
	loyaltyplantHref,
	genericOrderingHref,
	menuHref,
	nextSharesBg,
	nextOverhangs,
	nextIsFooter,
	previousOverhangs,
	color = 'beige',
}: PageBodyLocationIntroProps) => {
	const orderNowHref =
		genericOrderingHref || loyaltyplantHref || DEFAULT_ONLINE_ORDERING_URL
	const scheme = colorSchemes[color]

	return (
		<BoundedBox
			as="section"
			id={id}
			nextSharesBg={nextSharesBg}
			nextOverhangs={nextOverhangs}
			previousOverhangs={previousOverhangs}
			innerMaxWidthClassName="max-w-70rem"
			plClassName="pl-4 md:pl-8 lg:pl-10"
			prClassName="pr-4 md:pr-8 lg:pr-10"
			pbClassName={nextIsFooter ? undefined : 'pb-0'}
			className="relative flex max-w-screen-xl mx-auto bg-yellow-50"
		>
			<div className="absolute inset-0 overflow-hidden pointer-events-none select-none">
				<StaticImage
					src="../../static/images/texture-dots-surfboards.png"
					alt=""
					className="w-full h-full"
					placeholder="blurred"
					objectFit="cover"
					quality={50}
					sizes="100vw"
					layout="fullWidth"
					formats={['avif', 'webp']}
				/>
			</div>
			<div className="absolute inset-0 overflow-hidden pointer-events-none select-none">
				<div className="max-w-screen-lg mx-auto">
					<div className="relative aspect-w-4 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
						<div>
							<div className="absolute top-0 left-0 transform w-24rem md:w-36rem lg:w-46rem -translate-x-1/3 -translate-y-1/3 lg:-translate-x-1/2">
								<StaticImage
									src="../../static/images/texture-starburst.png"
									alt=""
									quality={50}
									layout="fullWidth"
									placeholder="none"
									formats={['avif', 'webp']}
									sizes="60vw"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				className={clsx(
					'relative',
					!nextIsFooter && 'z-10 -mb-5 md:-mb-9 lg:-mb-12',
				)}
			>
				<div
					className={clsx(
						'shadow-lg md:shadow-xl lg:shadow-2xl py-7',
						scheme.bg,
					)}
				>
					<div className="max-w-[52rem] w-full mx-auto">{scheme.image}</div>

					<div className="relative text-center mt-8 sm:flex sm:items-end sm:justify-between sm:text-left md:mt-2 px-5 sm:px-8 sm:space-x-4">
						<div>
							{type === 'Mixplate' && (
								<Text
									variant="sans-18"
									className="font-bold tracking-wider uppercase text-teal-40"
								>
									Mixplate
								</Text>
							)}

							{title && (
								<Text
									as="h1"
									variant="sans-35-40"
									className={clsx('font-extrabold mt-4', scheme.heading)}
								>
									{title}
								</Text>
							)}

							{locationDetails && (
								<Text
									as="p"
									variant="sans-18"
									className={clsx('font-semibold mt-3', scheme.details)}
								>
									{locationDetails}
								</Text>
							)}
						</div>

						<ul className="flex flex-wrap -ml-4 justify-center mt-2 sm:-mt-4">
							<li className="pt-4 pl-4">
								<ButtonLink
									variant={scheme.orderButtonVariant}
									href={orderNowHref}
									target="_blank"
								>
									Order Now
								</ButtonLink>
							</li>

							{menuHref && (
								<li className="pt-4 pl-4">
									<ButtonLink
										variant={scheme.menuButtonVariant}
										href={menuHref}
										target="_blank"
									>
										Menu
									</ButtonLink>
								</li>
							)}
						</ul>
					</div>
				</div>
			</div>
		</BoundedBox>
	)
}

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyLocationIntroFragment,
	typeof mapDataToContext
>) => {
	const title = data.data?.title.text ?? ''
	const hashNum = hash(title)
	const color = schemes[hashNum % schemes.length] ?? 'beige'

	return {
		title,
		type: data.data?.type,
		locationDetails: data.data?.location_details?.text,
		menuHref: data.data?.menu?.url,
		loyaltyplantHref: data.data?.loyaltyplant_url?.url,
		genericOrderingHref: data.data.generic_ordering_url?.url,
		color,
	}
}

export const mapDataToContext = () => ({
	bg: Symbol(),
	overhangsBottom: true,
})

export const fragment = graphql`
	# Note: This isn't a slice fragment since this slice does not exist in Prismic.
	fragment PageBodyLocationIntro on PrismicLocation {
		data {
			title {
				text
			}
			type
			location_details {
				text
			}
			loyaltyplant_url {
				url
			}
			generic_ordering_url {
				url
			}
			menu {
				url
			}
		}
	}
`

export default PageBodyLocationIntro
