import { create } from 'zustand'

type Speedbump = {
	isOpen: boolean
	href: string
	open: (href: string) => void
	close: () => void
}

export const useSpeedbumpStore = create<Speedbump>((set) => ({
	isOpen: false,
	href: '',
	open: (href: string) => set(() => ({ isOpen: true, href })),
	close: () => set(() => ({ isOpen: false })),
}))
