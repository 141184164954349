import * as Menu from '@radix-ui/react-navigation-menu'
import { useNavigation } from '../../hooks/useNavigation'
import { Link } from '../Link'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { NavItem } from './NavItem'

type Props = {
	location: Location | undefined
}

export const DesktopNavigation = (props: Props) => {
	const navigations = useNavigation()

	return (
		<div className="flex-col justify-center justify-self-end -mt-3 -ml-8 items-end gap-2 hidden lg:flex lg:gap-5 lg:pt-3">
			<Menu.Root orientation="horizontal" aria-label="Secondary">
				<Menu.List className="flex flex-wrap items-center gap-8">
					{navigations.secondary
						.filter((i) => Boolean(i.primary.link?.url))
						.map((i) => (
							<NavItem
								key={i.primary.name}
								name={i.primary.name}
								href={i.primary.link!.url!}
								location={props.location}
								type="secondary"
							/>
						))}

					<Menu.Item className="text-white">
						<Menu.Link asChild>
							<Link href="/search">
								<span className="sr-only">Search site</span>
								<MagnifyingGlassIcon className="w-5" />
							</Link>
						</Menu.Link>
					</Menu.Item>
				</Menu.List>
			</Menu.Root>

			<Menu.Root orientation="horizontal" aria-label="Primary">
				<Menu.List className="flex flex-wrap items-center gap-8">
					{navigations.primary
						.filter((i) => Boolean(i.primary.link?.url))
						.map((i) => (
							<NavItem
								key={i.primary.name}
								name={i.primary.name}
								href={i.primary.link!.url!}
								location={props.location}
								subMenu={i.items
									.filter((c) => Boolean(c.link?.url))
									.map((c) => ({ name: c.name, href: c.link!.url! }))}
							/>
						))}
				</Menu.List>
			</Menu.Root>
		</div>
	)
}
