import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent'
import { FancyHeading } from '../components/FancyHeading'
import { Text } from '../components/Text'
import { ButtonLink } from '../components/ButtonLink'

export type PageBodyHeroSideImageProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyHeroSideImage = ({
	image,
	imageAlt,
	textHTML,
	id,
	heading,
	link,
	linkText,
	subheading,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
}: PageBodyHeroSideImageProps): JSX.Element => (
	<BoundedBox
		as="section"
		id={id}
		nextSharesBg={nextSharesBg}
		nextOverhangs={nextOverhangs}
		previousOverhangs={previousOverhangs}
		className="mx-auto text-gray-10 max-w-screen-xl bg-yellow-50"
		innerMaxWidthClassName="max-w-[80rem]"
	>
		<div className="grid gap-8 lg:grid-cols-2 lg:gap-0 lg:items-center lg+:grid-cols-[1fr,42.3125rem]">
			<div className="lg:pr-10">
				{subheading && (
					<Text
						variant="sans-18-24"
						className="text-teal-30 font-bold uppercase"
					>
						{subheading}
					</Text>
				)}

				{heading && (
					<FancyHeading variant="redWhite" className="mt-5 md:mt-7" as="h1">
						{heading}
					</FancyHeading>
				)}

				{textHTML && (
					<HTMLContent html={textHTML} className="mt-7 md:mt-9 max-w-xl" />
				)}

				{link && linkText && (
					<ButtonLink href={link} className="mt-3 lg:mt-5">
						{linkText}
					</ButtonLink>
				)}
			</div>

			{image && (
				<div className="shadow-lg aspect-[677/546] bg-white max-w-screen-sm lg:max-w-none lg+:-mb-[8rem]">
					<GatsbyImage
						image={image}
						alt={imageAlt ?? ''}
						className="h-full"
						objectFit="contain"
						objectPosition="center"
					/>
				</div>
			)}
		</div>
	</BoundedBox>
)

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyHeroSideImageFragment,
	typeof mapDataToContext
>) => ({
	heading: data.primary.heading.text,
	subheading: data.primary.subheading.text,
	textHTML: data?.primary?.copy?.html,
	image: getImage(data.primary?.image),
	imageAlt: data.primary?.image?.alt,
	link: data.primary.link?.url,
	linkText: data.primary.link_text,
})

export const mapDataToContext = () => ({
	bg: 'bg-yellow-50',
	overhangsBottom: [false, false, true],
})

export const fragment = graphql`
	fragment PageBodyHeroSideImage on PrismicPageDataBodyHeroSideImage {
		primary {
			image {
				alt
				gatsbyImageData(
					width: 1000
					layout: FULL_WIDTH
					placeholder: BLURRED
					sizes: "(min-width: 120rem) 50vw, (min-width: 64rem) 40vw, (min-width: 48rem) 25vw, 100vw"
				)
			}
			heading {
				text
			}
			subheading {
				text
			}
			link {
				url
			}
			link_text
			copy {
				text
				html
			}
		}
	}
`

export default PageBodyHeroSideImage
