import * as Dialog from '@radix-ui/react-dialog'
import * as React from 'react'
import { useSpeedbumpStore } from './store'
import { Icon } from '../Icon'
import { FancyHeading } from '../FancyHeading'
import { BoundedBox } from '../BoundedBox'
import { Button } from '../Button'
import { ButtonLink } from '../ButtonLink'

const NOTICE =
	'You are navigating to a site that is controlled by a third party not affiliated with L&L Hawaiian Barbecue. The content, policies, and accessibility features on the website you are entering may be different.\n\nL&L Hawaiian Barbecue is not responsible for the policies or practices of third-party websites.'

export const SpeedbumpDialog = () => {
	const state = useSpeedbumpStore()

	return (
		<Dialog.Root
			open={state.isOpen}
			onOpenChange={(open) => {
				if (open) state.open(state.href)
				else state.close()
			}}
		>
			<Dialog.Portal>
				<Dialog.Overlay className="fixed bg-black/60 inset-0 z-30" />
				<Dialog.Content className="z-30 w-full mx-auto outline-none md:my-12 max-w-[37.5rem] fixed inset-0 flex flex-col justify-center">
					<div className="shadow-lg md:shadow-xl lg:shadow-2xl relative bg-white">
						<BoundedBox className="text-white bg-red-50" variant="modal">
							<Dialog.Title asChild>
								<FancyHeading as="h2" textVariant="sans-24-35" variant="yellow">
									Attention
								</FancyHeading>
							</Dialog.Title>

							<Dialog.Description className="text-white sans-18-1_25 font-medium mt-5 whitespace-pre-line">
								{NOTICE}
							</Dialog.Description>

							<div className="flex mt-7 space-x-3 justify-end">
								<Dialog.Close asChild>
									<Button onClick={state.close} variant="beigeSmall">
										Cancel
										<span className="sr-only">navigation to external site</span>
									</Button>
								</Dialog.Close>
								<ButtonLink
									href={state.href}
									speedbump={false}
									variant="beigeSmall"
									onClick={state.close}
								>
									Confirm
									<span className="sr-only">navigation to external site</span>
								</ButtonLink>
							</div>
						</BoundedBox>
					</div>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	)
}
