import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import * as Dialog from '@radix-ui/react-dialog'
import XCircleIcon from '@heroicons/react/20/solid/XCircleIcon'

import { DEFAULT_ONLINE_ORDERING_URL, REGISTERED_MARK } from '../constants'
import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { Link } from '../components/Link'
import { Text } from '../components/Text'
import { Anchor } from '../components/Anchor'
import { SuperscriptCharacter } from '../components/SuperscriptCharacter'
import { MenuItemDialog } from '../components/MenuItemDialog'
import { PageBodyNutritionFactsProps } from './PageBodyNutritionFacts'
import { isMobile } from '../lib/isMobile'
import { useSiteSettings } from '../hooks/useSiteSettings'

export type PageBodyMenuItemsProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyMenuItems = ({
	children,
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
}: PageBodyMenuItemsProps): JSX.Element => (
	<BoundedBox
		as="section"
		id={id}
		nextSharesBg={nextSharesBg}
		nextOverhangs={nextOverhangs}
		previousOverhangs={previousOverhangs}
		innerMaxWidthClassName="max-w-screen-lg"
		className="mx-auto bg-white text-gray-10 max-w-screen-xl"
	>
		{children && (
			<ul className="flex flex-wrap items-start justify-center -mt-8 -ml-8 lg:-ml-12 lg:-mt-12">
				{children}
			</ul>
		)}
	</BoundedBox>
)

type MenuItemProps = {
	href: string
	title?: string | null
	description?: string | null
	descriptionHTML?: string | null
	image?: IGatsbyImageData | null
	imageAlt?: string | null
} & Omit<
	PageBodyNutritionFactsProps,
	keyof PageTemplateEnhancerProps | 'disclaimerHTML'
>

const MenuItem = ({
	href,
	title,
	description,
	descriptionHTML,
	image,
	imageAlt,
	calories,
	caloriesFromFat,
	totalFat,
	saturatedFat,
	cholesterol,
	sodium,
	totalCarbohydrates,
	dietaryFiber,
	sugar,
	protein,
}: MenuItemProps) => {
	const siteSettings = useSiteSettings()
	const [isOpen, setIsOpen] = React.useState(false)
	const handleLinkClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
		if (isMobile()) {
			return
		}

		e.preventDefault()
		setIsOpen(true)
	}

	const closeDialog = () => {
		if (isMobile()) {
			return
		}

		setIsOpen(false)
		window.history.back()
	}

	React.useEffect(() => {
		if (isMobile()) {
			return
		}

		if (isOpen) {
			window.history.pushState({}, '', href)

			const handlePop = () => setIsOpen(false)
			window.addEventListener('popstate', handlePop)

			return () => window.removeEventListener('popstate', handlePop)
		}
	}, [isOpen, href])

	return (
		<Dialog.Root
			open={isOpen}
			onOpenChange={(open) => (open ? setIsOpen(true) : closeDialog())}
		>
			<li className="w-full pt-8 pl-8 lg:pl-12 lg:pt-12 md:w-6/12 lg:w-4/12 relative">
				<div className="grid gap-8">
					{image && (
						<div>
							<GatsbyImage
								image={image}
								alt={imageAlt ?? ''}
								className="block mx-auto max-w-16rem lg:max-w-none"
							/>
						</div>
					)}

					{title && (
						<Text
							as="h3"
							variant="sans-40"
							className="font-extrabold text-center"
						>
							<Anchor
								href={href}
								onClick={handleLinkClick}
								className="before:inset-0 before:absolute"
								colorClassName="text-red-50"
								focusColorClassName="focus:underline"
								hoverColorClassName="hover:underline"
							>
								<SuperscriptCharacter character={REGISTERED_MARK}>
									{title}
								</SuperscriptCharacter>
							</Anchor>
						</Text>
					)}

					{description && (
						<Text as="p" variant="sans-18" className="text-center">
							{description}
						</Text>
					)}
				</div>

				<Dialog.Portal>
					<Dialog.Overlay className="fixed inset-0 bg-black/60" />
					<Dialog.Content
						aria-label={title ?? undefined}
						className="z-30 w-full mx-auto outline-none md:my-12 max-w-70rem fixed inset-0"
					>
						<Dialog.Title className="sr-only">{title}</Dialog.Title>
						<Dialog.Close
							className="text-red-50 absolute right-2 top-2"
							type="button"
						>
							<span className="sr-only">Close {title} dialog.</span>
							<XCircleIcon className="w-5 h-5" />
						</Dialog.Close>

						<MenuItemDialog
							hero={{
								heading: title || '',
								image: image ?? undefined,
								imageAlt: imageAlt ?? title,
								subheading: 'Food',
							}}
							facts={{
								calories,
								caloriesFromFat,
								totalFat,
								saturatedFat,
								cholesterol,
								sodium,
								totalCarbohydrates,
								dietaryFiber,
								sugar,
								protein,
								disclaimerHTML: siteSettings.nutritionFactsDisclaimerHTML || '',
							}}
							text={{
								textHTML: descriptionHTML || '',
								buttonText: 'Order Now',
								buttonHref: DEFAULT_ONLINE_ORDERING_URL,
							}}
						/>
					</Dialog.Content>
				</Dialog.Portal>
			</li>
		</Dialog.Root>
	)
}

PageBodyMenuItems.MenuItem = MenuItem

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyMenuItemsFragment,
	typeof mapDataToContext
>) => ({
	children: data.items?.map((item) => {
		const doc = item?.menu_item?.document

		return (
			doc &&
			'url' in doc && (
				<MenuItem
					key={doc.url}
					href={doc.url ?? '/'}
					title={doc.data?.title?.text}
					description={doc.data?.description?.text}
					descriptionHTML={doc.data?.description?.html}
					image={getImage(doc.data?.featured_image)}
					imageAlt={doc.data?.featured_image?.alt}
					calories={doc.data?.calories}
					caloriesFromFat={doc.data?.calories_from_fat}
					totalFat={doc.data?.total_fat}
					saturatedFat={doc.data?.saturated_fat}
					cholesterol={doc.data?.cholesterol}
					sodium={doc.data?.sodium}
					totalCarbohydrates={doc.data?.total_carbohydrates}
					dietaryFiber={doc.data?.dietary_fiber}
					sugar={doc.data?.sugar}
					protein={doc.data?.protein}
				/>
			)
		)
	}) as React.ReactNode | undefined,
})

export const mapDataToContext = () => ({
	bg: 'bg-white',
})

export const fragment = graphql`
	fragment PageBodyMenuItems on PrismicPageDataBodyMenuItems {
		items {
			menu_item {
				document {
					... on PrismicMenuItem {
						url
						data {
							title {
								text
							}
							description {
								text
								html
							}
							featured_image {
								alt
								gatsbyImageData(
									width: 500
									sizes: "(min-width: 48rem) 19rem, 16rem"
									placeholder: BLURRED
								)
							}
							calories
							calories_from_fat
							total_fat
							saturated_fat
							cholesterol
							sodium
							total_carbohydrates
							dietary_fiber
							sugar
							protein
						}
					}
				}
			}
		}
	}
`

export default PageBodyMenuItems
