import { graphql } from 'gatsby'
import { BoundedBox } from '../components/BoundedBox'
import { PageTemplateEnhancerProps } from '../templates/page'
import {
	MapDataToContextArgs,
	MapDataToPropsArgs,
} from '../lib/mapSlicesToComponents'
import { Text } from '../components/Text'
import { HTMLContent } from '../components/HTMLContent'
import clsx from 'clsx'
import { ButtonLink } from '../components/ButtonLink'

const variants = {
	White: {
		backgroundClassName: 'bg-white',
		circleBackgroundClassName: 'bg-yellow-50',
	},
	Yellow: {
		backgroundClassName: 'bg-yellow-50',
		circleBackgroundClassName: 'bg-white',
	},
}

const defaultVariant = 'White'

export type PageBodyStepsProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodySteps = ({
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
	heading,
	variant: variantName = defaultVariant,
	buttonText = 'Learn More',
	buttonHref,
	footerText,
	steps = [],
}: PageBodyStepsProps) => {
	const variant = variants[variantName]

	return (
		<BoundedBox
			as="section"
			id={id}
			variant="base"
			nextSharesBg={nextSharesBg}
			nextOverhangs={nextOverhangs}
			previousOverhangs={previousOverhangs}
			innerMaxWidthClassName="max-w-[66.875rem]"
			className={clsx(variant.backgroundClassName)}
		>
			<div className="flex flex-col items-center justify-center">
				{heading && (
					<Text
						as="h2"
						variant="sans-35-40"
						className="text-red-30 font-extrabold text-center"
					>
						{heading}
					</Text>
				)}

				<div className="grid md:grid-cols-2 lg:grid-cols-3 items-start w-auto md:w-full mt-15 place-content-between gap-10 mx-auto">
					{steps.length > 0 &&
						steps.map((step, idx) => (
							<StepText
								key={step}
								stepNumber={idx + 1}
								stepText={step}
								backgroundClass={variant.circleBackgroundClassName}
							/>
						))}
				</div>

				{buttonHref && (
					<ButtonLink variant="redLarge" href={buttonHref} className="mt-10">
						{buttonText}
					</ButtonLink>
				)}

				{footerText && (
					<HTMLContent
						className="mt-13 text-center"
						html={footerText}
						componentOverrides={{
							p: (Comp) => (props) => (
								<Comp
									as="p"
									className="text-gray-10"
									variant="sans-14"
									{...props}
								/>
							),
						}}
					/>
				)}
			</div>
		</BoundedBox>
	)
}

interface Props {
	stepNumber: number
	stepText: string | null
	backgroundClass: string
}

const StepText = ({ stepNumber, stepText, backgroundClass }: Props) => {
	return (
		<div className="flex flex-col items-center justify-center">
			<div
				className={clsx(
					'py-5 px-6 flex items-center rounded-full',
					backgroundClass,
				)}
			>
				<Text variant="sans-40" className="text-red-50 font-extrabold">
					{stepNumber}
				</Text>
			</div>

			{stepText && (
				<HTMLContent
					html={stepText}
					className="text-center max-w-[277px] mt-8"
				/>
			)}
		</div>
	)
}

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyStepsFragment,
	typeof mapDataToContext
>) => ({
	variant: data?.primary?.variant as keyof typeof variants | undefined,
	heading: data?.primary?.heading?.text,
	buttonText: data.primary?.button_text?.text,
	buttonHref: data.primary?.button_link?.url || undefined,
	footerText: data?.primary?.footer_text?.html,
	steps: data.items
		?.map((item) => item?.step?.text && item?.step.html)
		.filter(Boolean),
})

export const mapDataToContext = ({
	data,
}: MapDataToContextArgs<Queries.PageBodyStepsFragment>) => {
	const variant =
		variants[(data.primary?.variant as keyof typeof variants) ?? defaultVariant]

	return {
		bg: variant.backgroundClassName,
	}
}

export const fragment = graphql`
	fragment PageBodySteps on PrismicPageDataBodySteps {
		primary {
			variant
			heading {
				text
			}

			button_text {
				text
			}

			button_link {
				url
			}

			footer_text {
				text
				html
			}
		}
		items {
			step {
				text
				html
			}
		}
	}
`

export default PageBodySteps
