import { useSiteSettings } from '../hooks/useSiteSettings'
import { useNavigation } from '../hooks/useNavigation'
import { PageTemplateEnhancerProps } from '../templates/page'

import { Anchor } from '../components/Anchor'
import { BoundedBox } from '../components/BoundedBox'
import { Icon, IconProps } from '../components/Icon'
import { Link } from '../components/Link'
import { Text } from '../components/Text'

import { ReactComponent as AssetTextureLLHawaiiSurfboardSVG } from '../assets/texture-llhawaii-surfboard.svg'
import { HTMLContent } from '../components/HTMLContent'

type SocialIconAnchorItemProps = {
	href: string
	iconName: IconProps['name']
	label: string
}

const SocialIconAnchorItem = ({
	href,
	iconName,
	label,
}: SocialIconAnchorItemProps) => (
	<li>
		<Anchor
			href={href}
			colorClassName="text-red-50"
			focusColorClassName="focus:text-teal-40"
			hoverColorClassName="hover:text-teal-40"
		>
			<span className="sr-only">{label}</span>
			<Icon name={iconName} className="w-8" />
		</Anchor>
	</li>
)

export type PageBodyFooterProps = PageTemplateEnhancerProps

const PageBodyFooter = () => {
	const siteSettings = useSiteSettings()
	const navigation = useNavigation()
	const footerNavigation = navigation.footer

	return (
		<BoundedBox
			as="footer"
			innerMaxWidthClassName="max-w-screen-md"
			ptClassName="pt-11"
			pbClassName="pb-11"
			className="text-white bg-gray-10"
		>
			<div className="grid gap-4">
				<div className="grid justify-center">
					<nav aria-label="Socials">
						<ul className="flex flex-wrap items-center justify-center gap-7">
							{siteSettings.facebookURL && (
								<SocialIconAnchorItem
									href={siteSettings.facebookURL}
									iconName="facebookCircle"
									label="L&L Facebook"
								/>
							)}
							{siteSettings.instagramURL && (
								<SocialIconAnchorItem
									href={siteSettings.instagramURL}
									iconName="instagramCircle"
									label="L&L Instagram"
								/>
							)}
							{siteSettings.twitterURL && (
								<SocialIconAnchorItem
									href={siteSettings.twitterURL}
									iconName="twitterCircle"
									label="L&L Twitter"
								/>
							)}
							{siteSettings.youtubeURL && (
								<SocialIconAnchorItem
									href={siteSettings.youtubeURL}
									iconName="youtubeCircle"
									label="L&L YouTube"
								/>
							)}
						</ul>
					</nav>

					<nav aria-label="Footer">
						{footerNavigation.length > 0 && (
							<ul className="flex flex-wrap items-center justify-center gap-7">
								{footerNavigation.map(
									(item) =>
										item?.primary?.link?.url && (
											<li key={item.primary.link.url}>
												<Text
													variant="sans-14"
													className="font-extrabold tracking-wide uppercase"
												>
													<Anchor
														href={item.primary.link.url}
														className="py-6 block"
														colorClassName="text-white"
														focusColorClassName="focus:text-teal-40"
														hoverColorClassName="hover:text-teal-40"
													>
														{item.primary.name}
													</Anchor>
												</Text>
											</li>
										),
								)}
							</ul>
						)}
					</nav>
				</div>

				<div className="grid gap-5 justify-items-center">
					<Link href="/" className="block max-w-full w-15rem">
						<span className="sr-only">
							L&amp;L Hawaiian Barbecue. Go to home page
						</span>
						<AssetTextureLLHawaiiSurfboardSVG className="block w-full" />
					</Link>

					{siteSettings.siteCopyright && (
						<Text variant="sans-14" className="font-medium text-center">
							{siteSettings.siteCopyright}
						</Text>
					)}
					{siteSettings.siteDisclaimerHTML && (
						<HTMLContent
							html={siteSettings.siteDisclaimerHTML}
							componentOverrides={{
								p: (Comp) => (props) => <Comp variant="sans-13" {...props} />,
								a: (Comp) => (props) => (
									<Comp
										colorClassName="text-white"
										focusColorClassName="focus:text-teal-40"
										hoverColorClassName="hover:text-teal-40"
										{...props}
									/>
								),
							}}
							className="font-medium text-center text-grayWarm-60"
						/>
					)}
					<Text
						variant="sans-13"
						className="font-medium text-center text-grayWarm-60"
					>
						Site Design & Development by{' '}
						<Anchor
							href="https://walltowall.com"
							colorClassName="text-white"
							focusColorClassName="focus:text-teal-40"
							hoverColorClassName="hover:text-teal-40"
						>
							Wall-to-Wall Studios
						</Anchor>
					</Text>
				</div>
			</div>
		</BoundedBox>
	)
}

export const mapDataToContext = () => ({
	bg: 'bg-gray-10',
})

export default PageBodyFooter
