import * as React from 'react'
import { SkipNav } from './SkipNavLink'
import { SpeedbumpDialog } from './SpeedbumpDialog'

export type LayoutProps = {
	children?: React.ReactNode
}

export const Layout = ({ children }: LayoutProps) => {
	return (
		<>
			<SkipNav />
			<main className="text-gray-10">{children}</main>
			<SpeedbumpDialog />
		</>
	)
}
