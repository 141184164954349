import * as Menu from '@radix-ui/react-navigation-menu'
import { isPathActive } from '@walltowall/helpers'
import { navigate } from 'gatsby'
import React from 'react'
import { Text } from '../Text'
import clsx from 'clsx'
import { Anchor } from '../Anchor'
import { Icon } from '../Icon'
import { useSpeedbumpStore } from '../SpeedbumpDialog/store'
import { SHOPIFY_URL } from '../../constants'

const navItemTypes = {
	primary: {
		variant: 'sans-14' as const,
		className: 'font-extrabold',
		activeClassName: 'focus:text-yellow-50 lg:focus:text-white',
	},
	secondary: {
		variant: 'sans-13' as const,
		className: 'font-bold',
		activeClassName: '',
	},
}

type Props = {
	name?: string | null
	href: string
	location?: Location
	type?: keyof typeof navItemTypes
	subMenu?: Array<{ name?: string | null; href: string }>
}

export const NavItem = ({
	name,
	href,
	location,
	type = 'primary',
	subMenu = [],
}: Props) => {
	const isActive = location ? isPathActive(href, location) : false
	const navType = navItemTypes[type]
	const hasSubMenu = subMenu.length > 0
	const openSpeedbump = useSpeedbumpStore((s) => s.open)

	return (
		<Menu.Item className="relative">
			<div className={clsx(hasSubMenu && 'pr-5', 'relative')}>
				<Text
					variant={navType.variant}
					className={clsx(navType.className, 'tracking-widest uppercase')}
				>
					<Menu.Link asChild active={isActive}>
						<Anchor
							href={href}
							onClick={(e) => {
								if (href.includes(SHOPIFY_URL)) {
									e.preventDefault()
									openSpeedbump(SHOPIFY_URL)
								}
							}}
							className="py-2 -my-2 relative"
							colorClassName="text-white group"
							focusColorClassName={navType.activeClassName}
							hoverColorClassName={navType.activeClassName}
						>
							{name}
							{type === 'primary' && (
								<span
									className="absolute left-0 right-0 pointer-events-none bottom-0 h-3px bg-yellow-50 group-hover:opacity-100 group-focus:opacity-100 transition duration-100 block group-data-[active]:opacity-100 opacity-0"
									aria-hidden
								/>
							)}
						</Anchor>
					</Menu.Link>
				</Text>

				{hasSubMenu && (
					<Menu.Trigger
						className="absolute inset-x-0 -inset-y-2 flex justify-end items-end"
						onMouseUp={(e) => {
							// Some screen readers will fire a synthetic pointer/mouse click
							// when keyboard interacting. This exists to prevent these
							// synthetic keyboard originating clicks from navigating to the
							// root page when they just want to open the sub menu.
							e.stopPropagation()

							// On real mouse clicks of the real submenu, we do just want to navigate.
							navigate(href)
						}}
					>
						<span className="sr-only">Toggle {name} sub-menu</span>
						<Icon name="chevronDown" className="w-3 mb-2" />
					</Menu.Trigger>
				)}
			</div>

			{hasSubMenu && (
				<Menu.Content className="absolute left-0 z-10 bg-white shadow -bottom-2 translate-y-full min-w-12rem border-t-3 border-b-3 border-yellow-50">
					<Menu.Sub>
						<Menu.List>
							{subMenu.map((i) => (
								<Menu.Item key={i.name} className="p-3">
									<Text
										variant="sans-15"
										className="font-bold whitespace-nowrap"
									>
										<Menu.Link asChild>
											<Anchor
												href={i.href}
												colorClassName="text-red-50"
												hoverColorClassName="hover:underline"
												focusColorClassName="focus:underline"
											>
												{i.name}
											</Anchor>
										</Menu.Link>
									</Text>
								</Menu.Item>
							))}
						</Menu.List>
					</Menu.Sub>
				</Menu.Content>
			)}
		</Menu.Item>
	)
}
