import * as React from 'react'
import { graphql } from 'gatsby'
import clsx from 'clsx'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import {
	MapDataToContextArgs,
	MapDataToPropsArgs,
} from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { ButtonLink } from '../components/ButtonLink'
import { StandardGrid } from '../components/StandardGrid'
import { StyledHTMLContent } from '../components/StyledHTMLContent'

import { ReactComponent as AssetTextureDotsSVG } from '../assets/texture-dots.svg'

export type PageBodyPromoBoxesProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyPromoBoxes = ({
	children,
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
	nextIsFooter,
	overhangsTop,
	overhangsBottom,
}: PageBodyPromoBoxesProps): JSX.Element => {
	return (
		<BoundedBox
			as="section"
			id={id}
			nextSharesBg={nextSharesBg}
			nextOverhangs={nextOverhangs}
			previousOverhangs={previousOverhangs}
			innerMaxWidthClassName="max-w-70rem"
			ptClassName={overhangsTop ? 'pt-0' : 'pt-0 md:pt-13 lg:pt-16'}
			pbClassName={
				overhangsBottom && !nextIsFooter ? 'pb-0' : 'pb-0 md:pb-13 lg:pb-16'
			}
			className="max-w-screen-xl mx-auto bg-white"
		>
			<div className="flex flex-col">
				<ul
					className={clsx(
						'-mx-6 bg-white md:-mx-0 md:shadow-xl lg:shadow-2xl md:relative md:z-10',
						overhangsTop && 'md:-mt-9 lg:-mt-12',
						overhangsBottom && !nextIsFooter && 'md:-mb-9 lg:-mb-12',
					)}
				>
					{children}
				</ul>
			</div>
		</BoundedBox>
	)
}

type PromoBoxProps = {
	index: number
	textHTML?: string | null
	image?: IGatsbyImageData
	imageAlt?: string | null
	buttonText?: string | null
	buttonHref?: string | null
	secondaryButtonText?: string | null
	secondaryButtonHref?: string | null
	variant?: 'tall' | 'default'
	socialLinks?: boolean
	colorScheme?: 'white' | 'teal'
}

const PromoBox = ({
	index,
	textHTML,
	image,
	imageAlt,
	buttonHref,
	buttonText = 'Learn more',
	secondaryButtonHref,
	secondaryButtonText = 'Learn more',
	variant = 'default',
	socialLinks = false,
	colorScheme = 'white',
}: PromoBoxProps) => {
	const imageIsOnRight = index % 2 === 1
	const isTall = variant === 'tall'
	const isWhite = colorScheme === 'white'

	return (
		<li className={clsx('border-b-2 border-gray-80 last:border-b-0')}>
			<StandardGrid
				gapXClassName="gap-0"
				className={clsx(
					'grid-flow-row-dense',
					isTall && 'md:min-h-[28.3125rem]',
				)}
			>
				<div
					className={clsx(
						'col-span-full md:col-span-4',
						imageIsOnRight && 'md:col-start-9',
					)}
				>
					<div className="h-full aspect-w-3 aspect-h-2 bg-gray-60 md:aspect-w-1 md:aspect-h-1">
						<div>
							{image && (
								<GatsbyImage
									image={image}
									alt={imageAlt ?? ''}
									className="h-full"
								/>
							)}
						</div>
					</div>
				</div>

				<BoundedBox
					className={clsx(
						'col-span-full md:col-span-8 relative overflow-hidden',
						imageIsOnRight && 'md:col-start-1',
						isWhite ? 'bg-white text-gray-10' : 'bg-teal-20 text-white',
					)}
				>
					<AssetTextureDotsSVG
						className={clsx(
							'absolute w-1/3 transform rotate-30 -left-1/4 -top-1/2 md:left-0',
							imageIsOnRight ? 'md:-translate-x-1/2' : 'md:-translate-y-1/4',
							isTall && 'md:-top-1/4 md:-translate-x-1/2',
							isWhite ? 'text-yellow-50' : 'text-white',
						)}
					/>

					<AssetTextureDotsSVG
						className={clsx(
							'absolute w-1/3 transform -right-1/4 md:-right-1/4 -bottom-1/3 md:bottom-0 rotate-30',
							isWhite ? 'text-yellow-50' : 'text-white',
						)}
					/>

					<div className="relative grid content-center h-full gap-7 md:gap-8 justify-items-center md:justify-items-start md:max-w-40rem md:ml-auto">
						{textHTML && (
							<StyledHTMLContent
								html={textHTML}
								className="text-center md:text-left max-w-50ch"
								componentOverrides={
									isWhite
										? undefined
										: {
												h1: (Comp) => (props) => (
													<Comp className="text-yellow-60" {...props} />
												),
												h2: (Comp) => (props) => (
													<Comp className="text-white" {...props} />
												),
											}
								}
							/>
						)}

						{(buttonHref || secondaryButtonHref) && (
							<div className="flex gap-6">
								{buttonHref && (
									<ButtonLink
										variant={isWhite ? 'tealLarge' : 'yellowLarge'}
										href={buttonHref}
									>
										<span className="sr-only">
											Learn more by visiting {buttonHref}
										</span>
										{buttonText}
									</ButtonLink>
								)}
								{secondaryButtonHref && (
									<ButtonLink
										variant={isWhite ? 'tealLarge' : 'yellowLarge'}
										href={secondaryButtonHref}
									>
										<span className="sr-only">
											Learn more by visiting {secondaryButtonHref}
										</span>
										{secondaryButtonText}
									</ButtonLink>
								)}
							</div>
						)}

						{socialLinks && (
							<div className="flex gap-6">
								<a
									href="https://www.facebook.com/hawaiianbarbecue"
									className="w-14 h-14"
								>
									<span className="sr-only">Facebook</span>
									<img src="/icons/facebook-yellow.svg" alt="" loading="lazy" />
								</a>

								<a
									href="https://www.instagram.com/llhawaiianbbq/?hl=en"
									className="w-14 h-14"
								>
									<span className="sr-only">Instagram</span>
									<img
										src="/icons/instagram-yellow.svg"
										alt=""
										loading="lazy"
									/>
								</a>

								<a
									href="https://www.youtube.com/@hawaiianbarbecue"
									className="w-14 h-14"
								>
									<span className="sr-only">YouTube</span>
									<img src="/icons/youtube-yellow.svg" alt="" loading="lazy" />
								</a>
							</div>
						)}
					</div>
				</BoundedBox>
			</StandardGrid>
		</li>
	)
}

PageBodyPromoBoxes.PromoBox = PromoBox

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyPromoBoxesFragment,
	typeof mapDataToContext
>) => ({
	children: data.items?.map((item, index) => (
		<PromoBox
			key={item?.text?.text}
			index={index}
			textHTML={item?.text?.html}
			image={getImage(item.image)}
			imageAlt={item?.image?.alt}
			buttonText={item?.button_text?.text}
			buttonHref={item?.button_link?.url}
			secondaryButtonText={item?.secondary_button_text}
			secondaryButtonHref={item?.secondary_button_link?.url}
			variant={data.items.length === 1 ? 'tall' : 'default'}
			socialLinks={item.social_links ?? false}
			colorScheme={
				(item.color_scheme?.toLowerCase() || 'white') as 'white' | 'teal'
			}
		/>
	)) as React.ReactNode | undefined,
	overhangsBottom:
		data.primary.overhang === 'Both' || data.primary.overhang === 'Bottom',
	overhangsTop:
		data.primary.overhang === 'Both' || data.primary.overhang === 'Top',
})

export const mapDataToContext = ({
	data,
}: MapDataToContextArgs<Queries.PageBodyPromoBoxesFragment>) => ({
	bgTop: [Symbol(), 'bg-white'],
	bgBottom: 'bg-white',
	overhangsBottom: [
		false,
		data.primary.overhang === 'Both' || data.primary.overhang === 'Bottom',
	],
	overhangsTop: [
		false,
		data.primary.overhang === 'Both' || data.primary.overhang === 'Top',
	],
})

export const fragment = graphql`
	fragment PageBodyPromoBoxes on PrismicPageDataBodyPromoBoxes {
		primary {
			overhang
		}
		items {
			color_scheme
			image {
				alt
				gatsbyImageData(
					width: 500
					sizes: "(min-width: 48rem) 24rem, 100vw"
					layout: FULL_WIDTH
					placeholder: BLURRED
				)
			}
			text {
				text
				html
			}
			button_text {
				text
			}
			button_link {
				url
			}
			secondary_button_text
			secondary_button_link {
				url
			}
			social_links
		}
	}
`

export default PageBodyPromoBoxes
