import * as Menu from '@radix-ui/react-navigation-menu'
import * as Dialog from '@radix-ui/react-dialog'
import { Icon } from '../Icon'
import React from 'react'
import { useNavigation } from '../../hooks/useNavigation'
import { NavItem } from '../DesktopNavigation/NavItem'
import { MobileNavItem } from './MobileNavItem'
import { Button } from '../Button'
import clsx from 'clsx'
import * as styles from './index.module.css'
import { NearestLocationBox } from '../NearestLocationBox'

type Props = {
	openNewsletterDialog: () => void
	location: Location | undefined
}

export type MobileNavigationElement = {
	closeMobileMenu: () => void
}

export const MobileNavigation = React.forwardRef<
	MobileNavigationElement,
	Props
>((props, ref) => {
	const [open, setOpen] = React.useState(false)
	const navigations = useNavigation()

	React.useImperativeHandle(
		ref,
		() => ({ closeMobileMenu: () => setOpen(false) }),
		[],
	)

	return (
		<div className="items-center justify-end grid gap-6 grid-flow-col lg:hidden">
			{navigations.mobileHeader.length > 0 && (
				<Menu.Root orientation="horizontal" aria-label="Primary">
					<Menu.List className="flex flex-wrap items-center justify-end gap-5">
						{navigations.mobileHeader
							.filter((i) => Boolean(i.primary.link?.url))
							.map((i) => (
								<NavItem
									key={i.primary.name}
									name={i?.primary?.name}
									href={i.primary.link!.url!}
									location={props.location}
								/>
							))}
					</Menu.List>
				</Menu.Root>
			)}

			<Dialog.Root open={open} onOpenChange={setOpen}>
				<Dialog.Trigger className="px-6 py-3 -mx-6 -my-4 focus:text-yellow-50 hover:text-yellow-50 transition">
					<span className="sr-only">Navigation</span>
					<Icon name="menu" className="w-7" />
				</Dialog.Trigger>

				<Dialog.Portal>
					<Dialog.Overlay
						className={clsx(styles.overlay, 'fixed inset-0 bg-black/50 z-30')}
					/>
					<Dialog.Content
						className={clsx(
							styles.content,
							'fixed inset-y-0 right-0 bg-white text-black w-[19rem] z-30 shadow-lg flex flex-col',
						)}
					>
						<Dialog.Close className="p-2 text-white bg-teal-40 hover:bg-teal-20 focus:bg-teal-20 transition absolute top-4 -left-3">
							<span className="sr-only">Close navigation</span>
							<Icon name="close" className="w-7" />
						</Dialog.Close>

						<Dialog.Title className="sr-only">Mobile Navigation</Dialog.Title>
						<Dialog.Description className="sr-only">
							Navigation menus for L&amp;L Hawaiian Barbecue
						</Dialog.Description>

						<Menu.Root
							orientation="vertical"
							className="flex flex-col justify-center flex-grow py-3 bg-yellow-50"
							aria-label="Mobile"
						>
							<Menu.List>
								{[...navigations.primary, ...navigations.secondary]
									.filter((i) => Boolean(i.primary.link?.url))
									.map((i) => (
										<MobileNavItem
											key={i.primary.name}
											name={i.primary.name}
											href={i.primary.link!.url!}
											subMenu={i.items
												.filter((c) => Boolean(c.link?.url))
												.map((c) => ({ name: c.name, href: c.link!.url! }))}
										/>
									))}

								<MobileNavItem name="Search" href="/search" />

								<Menu.Item className="flex justify-center py-3">
									<Button
										type="submit"
										variant="tealSmall"
										onClick={props.openNewsletterDialog}
									>
										<span className="sr-only">
											Open Mailing list dialog form.
										</span>
										Connect With Us
									</Button>
								</Menu.Item>
							</Menu.List>
						</Menu.Root>

						<NearestLocationBox />
					</Dialog.Content>
				</Dialog.Portal>
			</Dialog.Root>
		</div>
	)
})
