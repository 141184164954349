import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { isInternal } from '@walltowall/helpers'
import { useSpeedbumpStore } from './SpeedbumpDialog/store'
import { SHOPIFY_URL } from '../constants'

export type LinkProps = Omit<React.ComponentPropsWithoutRef<'a'>, 'as'> & {
	speedbump?: boolean
}

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
	({ href, speedbump = true, ...props }, ref) => {
		const openSpeedbump = useSpeedbumpStore((s) => s.open)

		if (!href) return <a ref={ref} {...props} />

		const internal = isInternal(href)
		if (!internal) {
			return (
				<a
					onClick={(e) => {
						if (href.includes(SHOPIFY_URL) && speedbump) {
							e.preventDefault()
							openSpeedbump(SHOPIFY_URL)
						}
					}}
					href={href}
					ref={ref}
					target="_blank"
					rel="noreferrer"
					{...props}
				/>
			)
		}

		return <GatsbyLink to={href} ref={ref as any} {...props} />
	},
)
