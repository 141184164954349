import * as React from 'react'

import { Button, ButtonProps } from './Button'
import { Link, LinkProps } from './Link'

type ButtonLinkProps = ButtonProps<'a'> & Pick<LinkProps, 'speedbump'>

export const ButtonLink = (props: ButtonLinkProps) => (
	<Button as={Link} {...props} />
)
