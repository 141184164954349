import * as React from 'react'
import clsx from 'clsx'
import { Text } from './Text'
import { Anchor } from './Anchor'
import { Address } from './Address'
import { Icon, IconProps } from './Icon'
import { ButtonLink } from './ButtonLink'
import AssetIconEzCater from '../assets/icon-ezcater.webp'

type OnlineOrderLinkProps = {
	href: string
	iconName: IconProps['name']
	label: string
}

const OnlineOrderLink = ({ iconName, href, label }: OnlineOrderLinkProps) => (
	<li className="pt-3 pl-3" title={label}>
		<div className="flex items-center justify-center w-6 h-6">
			<Anchor href={href}>
				<span className="sr-only">{label}</span>
				<Icon name={iconName} className="w-full h-full" />
			</Anchor>
		</div>
	</li>
)

type FeatureProps = {
	enabled?: boolean
	iconName: IconProps['name']
	enabledLabel: string
	disabledLabel: string
}

const Feature = ({
	enabled = false,
	iconName,
	enabledLabel,
	disabledLabel,
}: FeatureProps) => (
	<li className="pt-3 pl-3">
		<span className="sr-only">{enabled ? enabledLabel : disabledLabel}</span>
		<div
			className="flex items-center justify-center w-4 h-4"
			title={enabled ? enabledLabel : disabledLabel}
		>
			<Icon
				name={iconName}
				className={clsx(
					'w-full h-full',
					enabled ? 'text-gray-10' : 'text-gray-80',
				)}
			/>
		</div>
	</li>
)

export type LocationCardProps = {
	href: string

	title?: string
	type?: string | null
	streetAddress?: string | null
	city?: string | null
	state?: string | null
	zipCode?: string | null
	country?: string | null
	orderType: 'Pickup' | 'Delivery'

	ubereatsHref?: string | null
	doordashHref?: string | null
	postmatesHref?: string | null
	biteSquadHref?: string | null
	grubhubHref?: string | null
	loyaltyplantHref?: string | null
	ezCaterHref?: string | null
	genericOrderingHref?: string | null

	acceptsGiftCards?: boolean
	providesOnlineOrdering?: boolean
	providesDelivery?: boolean

	mapLabel?: string
} & React.ComponentPropsWithoutRef<'div'>

export const LocationCard = ({
	href,

	title,
	type,
	streetAddress,
	city,
	state,
	zipCode,
	country,
	orderType,

	ubereatsHref,
	doordashHref,
	postmatesHref,
	biteSquadHref,
	grubhubHref,
	loyaltyplantHref,
	ezCaterHref,
	genericOrderingHref,

	acceptsGiftCards,
	providesOnlineOrdering,
	providesDelivery,

	mapLabel,

	className,
	...restProps
}: LocationCardProps) => {
	const orderNowHref = genericOrderingHref || loyaltyplantHref

	return (
		<div
			className={clsx(
				'bg-white text-gray-10 p-6 md:p-7 flex flex-col',
				className,
			)}
			{...restProps}
		>
			{mapLabel && (
				<div className="p-3 -mt-6 text-white transform -translate-y-px md:-mt-7 bg-red-50 mr-auto">
					<Text
						variant="sans-14"
						className="font-extrabold tracking-wider text-center uppercase"
					>
						{mapLabel}
					</Text>
				</div>
			)}

			{type === 'Mixplate' && (
				<div className="p-2 justify-self-start bg-yellow-50 text-red-50">
					<Text
						as="span"
						variant="sans-14"
						className="block font-extrabold tracking-wide uppercase"
					>
						L&L Hawaiian Mixplate
					</Text>
				</div>
			)}

			<div className="flex items-center justify-between mt-2">
				{title && (
					<Text variant="sans-18" className="font-bold">
						<Anchor
							href={href}
							colorClassName="text-teal-10"
							focusColorClassName="focus:underline"
							hoverColorClassName="hover:underline"
						>
							{title}
						</Anchor>
					</Text>
				)}

				<ul className="flex flex-wrap -mt-3 -ml-3">
					<Feature
						iconName="locationCardOnlineOrdering"
						enabled={providesOnlineOrdering}
						enabledLabel="Provides mobile ordering"
						disabledLabel="Does not provide mobile ordering"
					/>
					<Feature
						iconName="locationCardDelivery"
						enabled={providesDelivery}
						enabledLabel="Provides delivery"
						disabledLabel="Does not provide delivery"
					/>
					<Feature
						iconName="locationCardGiftCard"
						enabled={acceptsGiftCards}
						enabledLabel="Accepts gift cards"
						disabledLabel="Does not accept gift cards"
					/>
				</ul>
			</div>

			<Text as="p" variant="sans-15" className="mt-3">
				<Address
					streetAddress={streetAddress}
					city={city}
					state={state}
					zipCode={zipCode}
					country={country}
				/>
			</Text>

			<div className="flex items-center space-x-2 mt-4">
				{orderNowHref && (
					<ButtonLink variant="redSmall" href={orderNowHref} target="_blank">
						Order Now
					</ButtonLink>
				)}

				<ButtonLink href={href} variant="tealSmall">
					<span className="sr-only">{title}</span>
					Location Info
				</ButtonLink>
			</div>

			{(ubereatsHref ||
				doordashHref ||
				postmatesHref ||
				biteSquadHref ||
				grubhubHref ||
				ezCaterHref) && (
				<ul className="flex flex-wrap -ml-3">
					{ubereatsHref && (
						<OnlineOrderLink
							iconName="ubereats"
							href={ubereatsHref}
							label={`Order UberEats from: ${title}`}
						/>
					)}
					{doordashHref && (
						<OnlineOrderLink
							iconName="doordash"
							href={doordashHref}
							label={`Order DoorDash from: ${title}`}
						/>
					)}
					{postmatesHref && (
						<OnlineOrderLink
							iconName="postmates"
							href={postmatesHref}
							label={`Order PostMates from: ${title}`}
						/>
					)}
					{biteSquadHref && (
						<OnlineOrderLink
							iconName="biteSquad"
							href={biteSquadHref}
							label={`Order Bite Squad from: ${title}`}
						/>
					)}
					{grubhubHref && (
						<OnlineOrderLink
							iconName="grubhub"
							href={grubhubHref}
							label={`Order GrubHub from: ${title}`}
						/>
					)}
					{ezCaterHref && (
						<li className="pt-3 pl-3">
							<div className="flex items-center justify-center w-6 h-6">
								<Anchor href={ezCaterHref}>
									<span className="sr-only">EzCater</span>
									<img
										className="w-full h-full object-contain"
										loading="lazy"
										src={AssetIconEzCater}
									/>
								</Anchor>
							</div>
						</li>
					)}
				</ul>
			)}
		</div>
	)
}
