import * as React from 'react'
import { GatsbyImage, type IGatsbyImageData } from 'gatsby-plugin-image'
import { Text } from './Text'
import clsx from 'clsx'
import { ButtonLink } from './ButtonLink'
import useEmblaCarousel from 'embla-carousel-react'
import { Transition } from '@headlessui/react'
import { StyledHTMLContent } from './StyledHTMLContent'
import { useUserLocation } from '../hooks/useUserLocation'
import { getOrderNowHref } from '../lib/getOrderNowHref'

import * as styles from './PlateSlider.module.css'

interface PlateSliderScreenProps extends PlateSliderProps {
	activeIdx: number
	activePlate: PlateSliderProps['plates'][number]
	setActiveIdx: React.Dispatch<React.SetStateAction<number>>
}

export const MobilePlateSlider = ({
	plates,
	className,
	activeIdx,
	activePlate,
	setActiveIdx,
}: PlateSliderScreenProps) => {
	const userLoc = useUserLocation(true)
	const orderHref = getOrderNowHref({ userLoc, fallback: activePlate.href })

	const [emblaRef, emblaApi] = useEmblaCarousel({
		breakpoints: {
			'(min-width: 64rem)': { active: false },
		},
	})

	React.useEffect(() => {
		const handleSelect = () => {
			setActiveIdx(emblaApi?.selectedScrollSnap() ?? 0)
		}

		emblaApi?.on('select', handleSelect)

		return () => {
			emblaApi?.off('select', handleSelect)
		}
	}, [emblaApi, setActiveIdx])

	return (
		<div className={clsx('lg:hidden', className)}>
			<div className="relative">
				<div className="absolute -inset-[8rem] opacity-60 md:max-w-[44rem] pointer-events-none">
					<img
						src="/images/starburst.gif"
						alt=""
						loading="lazy"
						className="w-full h-full"
					/>
				</div>

				<div
					className="overflow-hidden -mx-6 md:-mx-8 px-6 md:px-8"
					ref={emblaRef}
				>
					<div className="flex gap-20">
						{plates.map((plate) => (
							<div
								key={plate.title}
								className="flex-shrink-0 flex-grow-0 min-w-0 w-full basis-auto"
							>
								{plate.image && (
									<div className="relative max-w-lg">
										<div className="w-full">
											<GatsbyImage
												image={plate.image}
												alt={plate.imageAlt || plate.title || ''}
											/>
										</div>
									</div>
								)}
							</div>
						))}
					</div>
				</div>
			</div>

			{activePlate.title && (
				<Text
					as="h2"
					variant="sans-32"
					className="text-red-50 font-extrabold mt-5 relative"
				>
					{activePlate.title}
				</Text>
			)}

			{activePlate.textHTML && (
				<StyledHTMLContent
					html={activePlate.textHTML}
					className="mt-5 relative"
				/>
			)}

			<div className="flex space-x-3 mt-4">
				{plates.map((plate, idx) => (
					<button
						key={plate.title}
						className={clsx(
							idx === activeIdx ? 'w-6' : 'w-3',
							'bg-white rounded-full h-3',
						)}
						onClick={() => emblaApi?.scrollTo(idx)}
						style={{ transition: 'width .2s ease' }}
					>
						<span className="sr-only">
							Plate {idx + 1}: {plate.title}
						</span>
					</button>
				))}
			</div>

			{orderHref && (
				<ButtonLink href={orderHref} className="mt-8">
					<span aria-hidden>Order now</span>
					<span className="sr-only">Order {activePlate.title} now</span>
				</ButtonLink>
			)}
		</div>
	)
}

const DesktopPlateSlider = ({
	plates,
	className,
	activePlate,
	activeIdx,
	setActiveIdx,
}: PlateSliderScreenProps) => {
	const userLoc = useUserLocation(true)
	const orderHref = getOrderNowHref({ userLoc, fallback: activePlate.href })

	return (
		<div className={clsx('hidden lg:block', className)}>
			<div className="absolute -bottom-16 w-[400px] h-[400px] pointer-events-none right-[calc(((100%-min(90rem,100%))/2)+2rem)] lg+:w-[500px] lg+:h-[500px] full:w-[550px] full:h-[550px] full:right-[calc((100%-min(90rem,100%))/2)]">
				<div className="absolute inset-[-16rem] opacity-60 full:opacity-100">
					<img
						src="/images/starburst.gif"
						alt=""
						loading="lazy"
						className="w-full h-full"
					/>
				</div>

				{plates.map((plate, idx) => {
					if (!plate.image) {
						return null
					}

					return (
						<Transition
							key={idx}
							show={idx === activeIdx}
							enter={styles.enter}
							enterFrom={styles.enterFrom}
							enterTo={styles.enterTo}
							leaveFrom={styles.leaveFrom}
							leaveTo={styles.leaveTo}
						>
							<div className="transition absolute inset-0 ease-out duration-700">
								<GatsbyImage
									image={plate.image}
									alt={plate.imageAlt ?? plate.title ?? ''}
									imgStyle={{ objectFit: 'contain', objectPosition: 'left' }}
								/>
							</div>
						</Transition>
					)
				})}
			</div>

			<div className="relative">
				{activePlate.title && (
					<Text
						variant="sans-24-30"
						className="text-red-50 font-extrabold mt-5 relative"
					>
						{activePlate.title}
					</Text>
				)}

				{activePlate.textHTML && (
					<StyledHTMLContent
						html={activePlate.textHTML}
						className="mt-5 relative max-w-md"
						componentOverrides={{
							p: (Comp) => (props) => (
								<Comp as="p" variant="sans-22" {...props} />
							),
						}}
					/>
				)}

				<div className="flex space-x-3 mt-4">
					{plates.map((plate, idx) => (
						<button
							key={plate.title}
							className={clsx(
								idx === activeIdx ? 'w-6' : 'w-3',
								'bg-white rounded-full h-3 [transition:width_.2s_ease]',
							)}
							onClick={() => setActiveIdx(idx)}
						>
							<span className="sr-only">
								Plate {idx + 1}: {plate.title}
							</span>
						</button>
					))}
				</div>

				{orderHref && (
					<ButtonLink href={orderHref} className="mt-8 lg:mt-12">
						<span aria-hidden>Order now</span>
						<span className="sr-only">Order {activePlate.title} now</span>
					</ButtonLink>
				)}
			</div>
		</div>
	)
}

interface PlateSliderProps {
	plates: {
		image: IGatsbyImageData | undefined | null
		imageAlt: string | undefined | null
		textHTML: string | undefined | null
		title: string | undefined | null
		href: string | undefined | null
	}[]
	className?: string
}

export const PlateSlider = ({ plates, className }: PlateSliderProps) => {
	const [activeIdx, setActiveIdx] = React.useState(0)
	const activePlate = plates[activeIdx]

	return (
		<>
			<MobilePlateSlider
				plates={plates}
				className={className}
				activeIdx={activeIdx}
				setActiveIdx={setActiveIdx}
				activePlate={activePlate}
			/>
			<DesktopPlateSlider
				plates={plates}
				className={className}
				activeIdx={activeIdx}
				setActiveIdx={setActiveIdx}
				activePlate={activePlate}
			/>
		</>
	)
}
