import * as React from 'react'
import { graphql } from 'gatsby'
import clsx from 'clsx'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { ButtonLink } from '../components/ButtonLink'
import { StyledHTMLContent } from '../components/StyledHTMLContent'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

export type PageBodyCallToActionBoxesProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyCallToActionBoxes = ({
	children,
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
	nextIsFooter,
}: PageBodyCallToActionBoxesProps): JSX.Element => (
	<BoundedBox
		as="section"
		id={id}
		nextSharesBg={nextSharesBg}
		nextOverhangs={nextOverhangs}
		previousOverhangs={previousOverhangs}
		innerMaxWidthClassName="max-w-70rem"
		ptClassName="pt-10 md:pt-0"
		pbClassName={nextIsFooter ? undefined : 'pb-10 md:pb-0'}
		className="max-w-screen-xl mx-auto bg-white text-gray-10"
	>
		<div className="flex flex-col">
			<div
				className={clsx(
					'-my-10 bg-white md:shadow-xl lg:shadow-2xl md:relative md:z-10 md:-mt-9 lg:-mt-12 md:grid md:grid-cols-3',
					nextIsFooter ? 'md:mb-0' : 'md:-mb-9 lg:-mb-12',
				)}
			>
				{children}
			</div>
		</div>
	</BoundedBox>
)

type CallToActionProps = {
	textHTML?: string | null
	image?: IGatsbyImageData
	imageAlt?: string | null
	buttonText?: string | null
	buttonHref?: string | null
}

const CallToAction = ({
	textHTML,
	image,
	imageAlt,
	buttonText = 'Learn more',
	buttonHref,
}: CallToActionProps) => (
	<>
		<div className="grid content-center gap-5 py-10 border-t-2 md:px-10 border-gray-80 first:border-t-0 md:border-t-0 md:gap-6 justify-items-center">
			{textHTML && (
				<StyledHTMLContent
					html={textHTML}
					componentOverrides={{
						h2: (Comp) => (props) => (
							<Comp as="h3" className="text-teal-20" {...props} />
						),
						h1: (Comp) => (props) => (
							<Comp as="p" className="text-red-30" {...props} />
						),
					}}
					className="text-center"
				/>
			)}

			{buttonHref && (
				<ButtonLink variant="yellowSmall" href={buttonHref}>
					<span className="sr-only">Learn more by visiting {buttonHref}</span>
					{buttonText}
				</ButtonLink>
			)}
		</div>

		<div className="hidden md:block aspect-w-1 aspect-h-1 bg-red-50 bg-pattern-red bg-w-80rem">
			<div>
				{image && (
					<GatsbyImage image={image} alt={imageAlt ?? ''} className="h-full" />
				)}
			</div>
		</div>
	</>
)

PageBodyCallToActionBoxes.CallToAction = CallToAction

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyCallToActionBoxesFragment,
	typeof mapDataToContext
>) => ({
	children: data.items?.map((item) => (
		<CallToAction
			key={item?.text?.text}
			textHTML={item?.text?.html}
			buttonText={item?.button_text?.text}
			buttonHref={item?.button_link?.url}
			image={getImage(item?.image)}
			imageAlt={item?.image?.alt}
		/>
	)) as React.ReactNode | undefined,
})

export const mapDataToContext = () => ({
	bg: 'bg-white',
	overhangsBottom: [false, true],
	overhangsTop: [false, true],
})

export const fragment = graphql`
	fragment PageBodyCallToActionBoxes on PrismicPageDataBodyCallToActionBoxes {
		items {
			image {
				alt
				gatsbyImageData(
					width: 500
					placeholder: BLURRED
					sizes: "24rem"
					layout: FULL_WIDTH
				)
			}
			text {
				text
				html
			}
			button_text {
				text
			}
			button_link {
				url
				target
			}
		}
	}
`

export default PageBodyCallToActionBoxes
