import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import useEmblaCarousel from 'embla-carousel-react'
import { getImage } from 'gatsby-plugin-image'
import clsx from 'clsx'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'
import { useUserLocation } from '../hooks/useUserLocation'

import { BoundedBox } from '../components/BoundedBox'
import { Text } from '../components/Text'
import { ButtonLink } from '../components/ButtonLink'

import * as styles from './PageBodyFeaturedMenuItems.module.css'
import { Icon } from '../components/Icon'
import { Link } from '../components/Link'
import { getOrderNowHref } from '../lib/getOrderNowHref'

const Carousel = ({
	menuItems = [],
}: Pick<PageBodyFeaturedMenuItemsProps, 'menuItems'>) => {
	const userLoc = useUserLocation(true)
	const [emblaRef, emblaApi] = useEmblaCarousel({
		align: 'start',
		containScroll: 'trimSnaps',
	})

	const prevSlide = () => emblaApi?.scrollPrev()
	const nextSlide = () => emblaApi?.scrollNext()

	return (
		<div className="mt-8 relative z-10">
			<div className="overflow-hidden py-1 -my-1 px-1 -mx-1" ref={emblaRef}>
				<div className="flex gap-6">
					{menuItems.map((item) => {
						const href = item.buttonHref ?? '/menu'
						const orderHref = getOrderNowHref({ userLoc })

						return (
							<div
								key={item.name}
								className={clsx(
									styles.item,
									'flex flex-col shadow min-w-0 flex-shrink-0 flex-grow-0 w-full lg:w-[calc(33%-.675rem)] md:w-[calc(50%-.75rem)] relative',
								)}
							>
								<div className="block aspect-w-397 aspect-h-271 bg-teal-40">
									<div className="py-4 px-7">
										{item.image && (
											<GatsbyImage
												image={item.image}
												alt={item.imageAlt ?? item.name ?? ''}
												className="w-full h-full"
											/>
										)}
									</div>
								</div>

								<div className="px-6 py-7 bg-white flex-grow flex flex-col">
									{item.name && (
										<Link
											href={href}
											className="before:inset-0 before:absolute hover:underline focus:underline text-red-50"
										>
											<Text variant="sans-24" className="font-extrabold">
												{item.name}
											</Text>
										</Link>
									)}

									{item.description && (
										<Text as="p" variant="sans-16" className="mt-5">
											{item.description}
										</Text>
									)}

									{orderHref && item.buttonText && (
										<div className="mt-auto relative">
											<ButtonLink href={orderHref} className="mt-8">
												<span aria-hidden>{item.buttonText}</span>
												<span className="sr-only">Order {item.name} now</span>
											</ButtonLink>
										</div>
									)}
								</div>
							</div>
						)
					})}
				</div>
			</div>

			<div className="flex gap-4 mt-8 lg:contents justify-center">
				<button
					className="flex justify-center items-center focus:outline-none focus-visible:ring lg:absolute lg:-left-8 lg:inset-y-1 lg:pr-3 hover:text-teal-40 focus:text-teal-40 transition xl:-left-10 xl:pr-5"
					onClick={prevSlide}
					title="Previous"
				>
					<Icon name="chevronDown" className="rotate-90 w-5" />
					<span className="sr-only">Previous Plate</span>
				</button>

				<button
					className="flex justify-center items-center focus:outline-none focus-visible:ring lg:absolute lg:-right-8 lg:inset-y-1 lg:pl-3 hover:text-teal-40 focus:text-teal-40 transition xl:-right-10 xl:pl-5"
					onClick={nextSlide}
					title="Next"
				>
					<Icon name="chevronDown" className="-rotate-90 w-5" />
					<span className="sr-only">Next Plate</span>
				</button>
			</div>
		</div>
	)
}

export type PageBodyFeaturedMenuItemsProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyFeaturedMenuItems = ({
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
	heading,
	menuItems,
}: PageBodyFeaturedMenuItemsProps) => (
	<BoundedBox
		as="section"
		id={id}
		nextSharesBg={nextSharesBg}
		nextOverhangs={nextOverhangs}
		previousOverhangs={previousOverhangs}
		innerMaxWidthClassName="max-w-90rem"
		className="bg-white text-gray-10 max-w-screen-xl mx-auto relative"
	>
		{heading && (
			<Text variant="sans-24-30" className="font-extrabold text-red-50" as="h2">
				{heading}
			</Text>
		)}

		<Carousel menuItems={menuItems} />
	</BoundedBox>
)

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyFeaturedMenuItemsFragment,
	typeof mapDataToContext
>) => ({
	heading: data.primary?.heading?.text,
	menuItems: data.items
		?.map((item) => {
			const doc = item?.menu_item?.document
			if (!doc || !('url' in doc)) return undefined!

			return {
				name: doc?.data?.title?.text,
				description: doc?.data?.description?.text,
				image: getImage(doc?.data?.featured_image),
				imageAlt: doc?.data?.featured_image?.alt,
				buttonText: 'Order Now',
				buttonHref: doc?.url,
			}
		})
		.filter(Boolean),
})

export const mapDataToContext = () => ({
	bg: 'bg-white',
})

export const fragment = graphql`
	fragment PageBodyFeaturedMenuItems on PrismicPageDataBodyFeaturedMenuItems {
		primary {
			heading {
				text
			}
		}
		items {
			menu_item {
				document {
					... on PrismicMenuItem {
						url
						data {
							title {
								text
							}
							description {
								text
								html
							}
							featured_image {
								alt
								gatsbyImageData(placeholder: BLURRED, width: 500)
							}
						}
					}
				}
			}
		}
	}
`

export default PageBodyFeaturedMenuItems
